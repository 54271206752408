import { useMutation, useQueryClient } from 'react-query';
import { trackEvent } from '../analytics/trackEvent';
import { useListCollectionItemsKeyPrefix } from './useListCollectionItems';
import {
  deleteCollectionItem,
  DeleteCollectionItemParams,
  editCollectionItemNote,
  EditCollectionItemNoteParams,
  updateCollectionItemPurchaseDetails,
  UpdateCollectionItemPurchaseDetailsParams,
  updateCollectionItemSaleDetails,
  UpdateCollectionItemSaleDetailsParams,
  addMultipleCollectionItems,
  convertGrade,
  ConvertGradeParams,
} from 'services/sciApi/collections/index';
import { CollectibleType } from 'sci-ui-components/types/collectibleType';
import {
  UpdateCollectionItemCategoryParams,
  AddOrUpdateCollectionItemParams,
  AddOrUpdateCustomCollectibleProps,
  addOrUpdateCollectionItem,
  updateCollectionItemCategory,
  addOrUpdateCustomCollectible,
  SellOrUpdateSoldCollectionItemParams,
  sellOrUpdateSoldCollectionItem,
} from 'services/sciApi/collections/index';
import {
  getQueryKey as getHistoryQueryKey,
  keyPrefix as historyKeyPrefix,
} from 'features/collection/useCollectionHistory';
import { getListCollectibleMovementsQueryKeyForCollection } from 'features/stats/useListCollectibleMovementsPaginated';
import { getListPlayerMovementsQueryKeyForCollection } from 'features/stats/useListPlayerMovementsPaginated';
import {
  useGlobalCollectionStats,
  getUseCollectionStatsQueryKey,
  useCollectionStatsKey,
} from 'features/collection/useCollectionStats';
import { showError, showSuccess } from 'services/toaster';
import { waitForConfirmation } from 'sci-ui-components/ConfirmationDialog';
import { getLimitForMembershipTierPermissionCategory, isFeatureEnabledForMembershipTier } from 'features/user/utils';
import useUser, { User } from 'features/user/useUser';
import { CollectionItem } from 'sci-ui-components/types/collectionItem';
import { collectibleNames } from 'sci-ui-components/collectibles/constants';
import { formatList } from 'sci-ui-components/utils/formatList';

export function isCollectionEnabled(user: User | undefined, collectibleType: CollectibleType) {
  return collectibleType !== 'sealed-wax-card' || isFeatureEnabledForMembershipTier(user, 'waxCollection');
}

export const collectionNotEnabledError = (
  <div>
    Your plan doesn&apos;t include a wax collection.{' '}
    <a href="https://www.sportscardinvestor.com/account/?action=subscriptions" target="_blank" rel="noreferrer">
      Upgrade your plan to track a wax collection.
    </a>
  </div>
);

export default function useCollectionItemMutations() {
  const queryClient = useQueryClient();
  const { data: user } = useUser();
  const { globalStats } = useGlobalCollectionStats();

  function isUserAtCollectionLimit(collectibleType: CollectibleType, newItemsCount = 0): boolean {
    const stats = globalStats;
    if (!isCollectionEnabled(user, collectibleType)) {
      return true;
    }

    const limit = getLimitForMembershipTierPermissionCategory(user, 'collection');

    return (
      limit !== Infinity &&
      !!stats?.numberOfOwnedUniqueCollectibles &&
      stats?.numberOfOwnedUniqueCollectibles + newItemsCount >= limit
    );
  }

  // NOTE: limit applied to  cards & sealed
  function getRemainingCollectionLimit(): number {
    const stats = globalStats;

    const limit = getLimitForMembershipTierPermissionCategory(user, 'collection');

    return limit === Infinity ? Infinity : limit - (stats?.numberOfOwnedUniqueCollectibles ?? 0);
  }

  function checkIfCollectionEnabled(collectibleType: CollectibleType) {
    if (!isCollectionEnabled(user, collectibleType)) {
      showError({
        description: collectionNotEnabledError,
      });
      return false;
    }
    return true;
  }

  function checkIfCanAddToCollection(collectibleType: CollectibleType) {
    if (!checkIfCollectionEnabled(collectibleType)) {
      return false;
    }
    if (isUserAtCollectionLimit(collectibleType)) {
      showError({
        description: (
          <div>
            You&apos;ve reached your plan&apos;s limit for items in your{' '}
            {collectibleType == 'sealed-wax-card' ? 'wax' : 'card'} collection.
            <a href="https://www.sportscardinvestor.com/account/?action=subscriptions" target="_blank" rel="noreferrer">
              Remove an item or upgrade your plan to add more items.
            </a>
          </div>
        ),
      });
      return false;
    }
    return true;
  }

  const addCollectibleToCollection = useMutation({
    mutationFn: async ({
      collectibleType,
      note,
      categoryId,
      ...params
    }: AddCollectibleToCollectionParams): Promise<CollectionItem | null> => {
      if (!checkIfCanAddToCollection(collectibleType)) {
        return null;
      }

      const addedCollectionItem = await addOrUpdateCollectionItem({
        ...params,
        collectibleType,
        mode: 'add',
      });
      if (note) {
        try {
          await editCollectionItemNote({
            collectionItemId: addedCollectionItem.id,
            note,
          });
        } catch (err) {
          showError({
            description: 'Failed to add note to collection item. Please try again',
          });
        }
      }
      if (categoryId) {
        try {
          await updateCollectionItemCategory({
            collectionItemId: addedCollectionItem.id,
            collectionCategoryId: categoryId,
          });
        } catch (err) {
          showError({
            description: 'Failed to assign Category. Please try again',
          });
        }
      }

      return addedCollectionItem;
    },
    mutationKey: ['add-to-collection'],
    onSuccess: (collectionItem, params) => {
      if (!collectionItem) return;

      // TODO: narrow down refetch to specific collectibleType
      queryClient.refetchQueries([useListCollectionItemsKeyPrefix]);
      queryClient.refetchQueries(getHistoryQueryKey({ type: params.collectibleType }));
      queryClient.invalidateQueries(
        getUseCollectionStatsQueryKey({
          params: {
            filters: {
              collectibleType: collectionItem.collectibleType,
            },
          },
        })
      );
      queryClient.invalidateQueries(getListCollectibleMovementsQueryKeyForCollection());
      queryClient.invalidateQueries(getListPlayerMovementsQueryKeyForCollection());
      showSuccess({
        description: 'Successfully added to Collection',
      });
    },
    onError: () => {
      showError({
        description: 'Failed to add to collection. Please try again',
      });
    },
  });

  const addCollectiblesToCollection = useMutation({
    mutationFn: async ({
      collectibleType,
      items,
    }: {
      collectibleType: AddCollectibleToCollectionParams['collectibleType'];
      items: Omit<AddCollectibleToCollectionParams, 'collectibleType'>[];
    }): Promise<boolean | null> => {
      if (!isCollectionEnabled(user, collectibleType)) {
        showError({
          description: collectionNotEnabledError,
        });
        return null;
      } else if (isUserAtCollectionLimit(collectibleType, items.length)) {
        showError({
          description: (
            <div>
              You&apos;ve reached your plan&apos;s limit for items in your{' '}
              {collectibleType == 'sealed-wax-card' ? 'wax' : 'card'} collection. You can add{' '}
              {getRemainingCollectionLimit()} items to your collection
              <a
                href="https://www.sportscardinvestor.com/account/?action=subscriptions"
                target="_blank"
                rel="noreferrer"
              >
                Remove an item or upgrade your plan to add more items.
              </a>
            </div>
          ),
        });
        return null;
      }

      return addMultipleCollectionItems({
        items,
        collectibleType,
      });
    },
    mutationKey: ['add-multiple-to-collection'],
    onSuccess: (collectionItem, params) => {
      if (!collectionItem) return;

      // TODO: narrow down refetch to specific collectibleType
      queryClient.refetchQueries([useListCollectionItemsKeyPrefix]);
      queryClient.refetchQueries(getHistoryQueryKey({ type: params.collectibleType }));
      queryClient.invalidateQueries(
        getUseCollectionStatsQueryKey({
          params: {
            filters: {
              collectibleType: params.collectibleType,
            },
          },
        })
      );
      queryClient.invalidateQueries(getListCollectibleMovementsQueryKeyForCollection());
      queryClient.invalidateQueries(getListPlayerMovementsQueryKeyForCollection());
      showSuccess({
        description: 'Successfully added to Collection',
      });
      trackEvent({
        eventName: 'COLLECTION_IMPORT_COMPLETED',
        collectibleCount: params.items.length,
        collectibleType: params.collectibleType,
      });
    },
    onError: (_, { collectibleType, items }) => {
      showError({
        description: 'Failed to add to collection. Please try again',
      });
      trackEvent({
        eventName: 'COLLECTION_IMPORT_FAILED',
        collectibleCount: items.length,
        collectibleType,
      });
    },
  });

  const addCustomCollectibleToCollection = useMutation({
    mutationFn: async ({
      collectibleType,
      note,
      categoryId,
      ...params
    }: AddCustomCollectibleToCollectionParams): Promise<CollectionItem | null> => {
      if (!isCollectionEnabled(user, collectibleType)) {
        showError({
          description: (
            <div>
              Your plan doesn&apos;t include a wax collection.
              <a
                href="https://www.sportscardinvestor.com/account/?action=subscriptions"
                target="_blank"
                rel="noreferrer"
              >
                Upgrade your plan to track a wax collection.
              </a>
            </div>
          ),
        });
        return null;
      } else if (isUserAtCollectionLimit(collectibleType)) {
        showError({
          description: (
            <div>
              You&apos;ve reached your plan&apos;s limit for items in your {collectibleNames[collectibleType].singular}{' '}
              collection.{' '}
              <a
                href="https://www.sportscardinvestor.com/account/?action=subscriptions"
                target="_blank"
                rel="noreferrer"
              >
                Remove an item or upgrade your plan to add more items.
              </a>
            </div>
          ),
        });
        return null;
      }

      const addedCustomCollectionItem = await addOrUpdateCustomCollectible({
        ...params,
        collectibleType,
        mode: 'add',
      });
      if (note) {
        try {
          await editCollectionItemNote({
            collectionItemId: addedCustomCollectionItem.id,
            note,
          });
        } catch (err) {
          showError({
            description: 'Failed to add note to collection item. Please try again',
          });
        }
      }
      if (categoryId) {
        try {
          await updateCollectionItemCategory({
            collectionItemId: addedCustomCollectionItem.id,
            collectionCategoryId: categoryId,
          });
        } catch (err) {
          showError({
            description: 'Failed to assign Category. Please try again',
          });
        }
      }
      return addedCustomCollectionItem;
    },
    mutationKey: ['add-to-collection-custom'],
    onSuccess: (createdItem, params) => {
      if (!createdItem) return;

      // TODO: narrow down refetch to specific collectibleType
      queryClient.refetchQueries([useListCollectionItemsKeyPrefix]);
      queryClient.refetchQueries(getHistoryQueryKey({ type: params.collectibleType }));
      queryClient.invalidateQueries(
        getUseCollectionStatsQueryKey({
          params: {
            filters: {
              collectibleType: params.collectibleType,
            },
          },
        })
      );
      queryClient.invalidateQueries(getListCollectibleMovementsQueryKeyForCollection());
      queryClient.invalidateQueries(getListPlayerMovementsQueryKeyForCollection());
      showSuccess({
        description: 'Successfully added to Collection',
      });
    },
    onError: () => {
      showError({
        description: 'Failed to add to collection. Please try again',
      });
    },
  });

  const markCollectionItemAsSoldMutation = useMutation({
    mutationFn: (params: Omit<SellOrUpdateSoldCollectionItemParams, 'mode'>) =>
      sellOrUpdateSoldCollectionItem({
        ...params,
        mode: 'add',
      }),
    mutationKey: `mark-colection-item-as-sold`,
    onSuccess: (response) => {
      queryClient.refetchQueries([useListCollectionItemsKeyPrefix]);
      queryClient.refetchQueries([historyKeyPrefix]);
      queryClient.invalidateQueries([useCollectionStatsKey]);
      queryClient.invalidateQueries(getListCollectibleMovementsQueryKeyForCollection());
      queryClient.invalidateQueries(getListPlayerMovementsQueryKeyForCollection());

      showSuccess({
        description: response.message,
      });
    },
    onError: () => {
      showError({
        description: 'Failed to mark collection item as sold. Please try again.',
      });
    },
  });

  // NOTE: this mutation allows updating multiple sections of collectible item
  type UpdateCollectionItemPart = 'Sale Details' | 'Notes' | 'Purchase Details' | 'Category' | 'Manual Details';
  const updateCollectionItem = useMutation({
    mutationFn: async ({
      saleDetails,
      note,
      purchaseDetails,
      category,
      collectibleType,
      customCollectible,
    }: {
      saleDetails?: UpdateCollectionItemSaleDetailsParams | null;
      note?: EditCollectionItemNoteParams | null;
      purchaseDetails?: UpdateCollectionItemPurchaseDetailsParams | null;
      category?: UpdateCollectionItemCategoryParams | null;
      customCollectible?: AddOrUpdateCustomCollectibleProps | null;
      collectibleType: CollectibleType;
    }) => {
      const errors: UpdateCollectionItemPart[] = [];
      const successes: UpdateCollectionItemPart[] = [];
      if (!checkIfCollectionEnabled(collectibleType)) {
        return {
          errors,
          successes,
        };
      }
      async function tryTo<TResult>(promise: Promise<TResult>, part: UpdateCollectionItemPart) {
        try {
          await promise;
          successes.push(part);
        } catch (err) {
          errors.push(part);
        }
      }

      if (customCollectible) {
        await tryTo(
          addOrUpdateCustomCollectible({ ...customCollectible, collectibleType, mode: 'update' }),
          'Manual Details'
        );
      }
      if (saleDetails) {
        await tryTo(updateCollectionItemSaleDetails(saleDetails), 'Sale Details');
      }
      if (purchaseDetails) {
        await tryTo(updateCollectionItemPurchaseDetails(purchaseDetails), 'Purchase Details');
      }
      if (category) {
        await tryTo(updateCollectionItemCategory(category), 'Category');
      }
      if (note) {
        await tryTo(editCollectionItemNote(note), 'Notes');
      }
      if (!successes.length && errors.length) {
        throw new Error(`Failed to update ${collectibleNames[collectibleType].shortSingular} ${formatList(errors)}`);
      }
      return {
        errors,
        successes,
      };
    },
    mutationKey: `edit-collection-item`,
    onSuccess: ({ successes, errors }, { collectibleType }) => {
      if (!successes.length) {
        return;
      }
      queryClient.refetchQueries([useListCollectionItemsKeyPrefix]);
      queryClient.refetchQueries(getHistoryQueryKey({ type: collectibleType }));
      queryClient.invalidateQueries(
        getUseCollectionStatsQueryKey({
          params: {
            filters: {
              collectibleType,
            },
          },
        })
      );
      queryClient.invalidateQueries(getListCollectibleMovementsQueryKeyForCollection());
      queryClient.invalidateQueries(getListPlayerMovementsQueryKeyForCollection());
      const collectibleName = collectibleNames[collectibleType].shortSingular;
      if (errors.length) {
        showError({
          description: `Failed to update ${collectibleName} ${formatList(errors)}. Please try again.`,
        });
      }
      showSuccess({
        description: `Successfully updated ${collectibleName} ${formatList(successes)}`,
      });
    },
    onError: (error) => {
      showError({
        description: `${(error as Error)?.message ?? 'Failed to update collection item'}. Please try again.`,
      });
    },
  });

  const convertCollectionItemGrade = useMutation(
    ['convert-collection-item-grade'],
    async (params: ConvertGradeParams) => {
      if (!checkIfCanAddToCollection('sports-card')) {
        return null;
      }
      return convertGrade(params);
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries([useListCollectionItemsKeyPrefix]);
        queryClient.invalidateQueries(getListCollectibleMovementsQueryKeyForCollection());
        queryClient.invalidateQueries(getListPlayerMovementsQueryKeyForCollection());
        showSuccess({
          message: 'Grade is successfully converted',
        });
      },
      onError: (error) => {
        console.error(error);
        showError({
          message: 'Failed to convert grade. Please try again',
        });
      },
    }
  );

  const deleteCollectionItemMutation = useMutation({
    mutationFn: (params: DeleteCollectionItemParams) => deleteCollectionItem({ id: params.id }),
    mutationKey: ['delete-collection-item'],
    onSuccess: async () => {
      queryClient.refetchQueries([useListCollectionItemsKeyPrefix]);
      queryClient.refetchQueries([historyKeyPrefix]);
      queryClient.invalidateQueries([useCollectionStatsKey]);
      queryClient.invalidateQueries(getListCollectibleMovementsQueryKeyForCollection());
      queryClient.invalidateQueries(getListPlayerMovementsQueryKeyForCollection());

      showSuccess({
        description: 'Successfully deleted collection item',
      });
    },
    onError: () => {
      showError({
        description: 'Failed to delete collection item. Please try again.',
      });
    },
  });

  const deleteCollectionItemWithConfirmation = async (id: number) => {
    trackEvent({
      eventName: 'COLLECTION_DELETE_STARTED',
      collectionItemId: id,
    });

    await waitForConfirmation({
      title: 'Delete Collection Item',
      description: `Are you sure you want to delete this collection item?`,
      onConfirm: async () =>
        await deleteCollectionItemMutation.mutateAsync(
          {
            id,
          },
          {
            onSuccess: () => {
              trackEvent({
                eventName: 'COLLECTION_DELETE_COMPLETED',
                collectionItemId: id,
              });
            },
            onError: () => {
              trackEvent({
                eventName: 'COLLECTION_DELETE_FAILED',
                collectionItemId: id,
              });
            },
          }
        ),
      onCancel: () =>
        trackEvent({
          eventName: 'COLLECTION_DELETE_CANCELLED',
          collectionItemId: id,
        }),
    });
  };

  const isMutating =
    addCollectibleToCollection.isLoading ||
    addCustomCollectibleToCollection.isLoading ||
    markCollectionItemAsSoldMutation.isLoading ||
    deleteCollectionItemMutation.isLoading ||
    convertCollectionItemGrade.isLoading;

  return {
    addCollectibleToCollection,
    addCustomCollectibleToCollection,
    markCollectionItemAsSold: markCollectionItemAsSoldMutation,
    deleteCollectionItem: deleteCollectionItemWithConfirmation,
    updateCollectionItem,
    isMutating,
    addCollectiblesToCollection,
    convertCollectionItemGrade,
  };
}

export type AddCollectibleToCollectionParams = AddOrUpdateCollectionItemParams & {
  categoryId?: number | null;
  note?: string | null;
};

export type AddCustomCollectibleToCollectionParams = AddOrUpdateCustomCollectibleProps & {
  categoryId?: number | null;
  note?: string | null;
};
