import { ReactNode } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { Column } from '../types';
import DefaultTitle, { Justify } from './DefaultTitle';
import TextCell from './TextCell';

import classes from './StatColumn.module.scss';
import { FormatStatValueFn, FormatStatValueParams } from 'hooks/useFormatStatValue';

export type Variant = 'primary' | 'secondary';

interface BaseParams<T> {
  getValue: (rowData: T) => number | null | undefined;
  highlightChange?: boolean;
  formatStatValue: FormatStatValueFn;
}

export default function createStatColumn<
  TRowData extends object,
  TRowKey extends string | number = number,
  TSortKey extends string = string,
  TFilters extends { [key: string]: any[] } = { [key: string]: any[] }
>({
  key,
  groupId,
  title,
  type,
  defaultValue,
  getValue,
  formatStatValue,
  getChangeValue,
  highlightChange,
  getIcon,
  signDisplay,
  getSubText,
  justify = 'center',
  width = 'minmax(112px, auto)',
  variant = 'primary',
  bypassStatWithSubTextClass = false,
  formatOverrides,
  getLinkUrl,
  additionalStatParams,
  ...other
}: Omit<Column<TRowData, TRowKey, TSortKey, TFilters>, 'renderData'> &
  Omit<FormatStatValueParams, 'value' | 'currencyCode' | 'convertPriceFromUSD'> &
  BaseParams<TRowData> & {
    title: ReactNode;
    getIcon?: (rowData: TRowData) => IconDefinition | null;
    getChangeValue?: (rowData: TRowData) => number | null | undefined;
    getSubText?: (rowData: TRowData) => ReactNode;
    justify?: Justify;
    variant?: Variant;
    bypassStatWithSubTextClass?: boolean;
    getLinkUrl?: (rowData: TRowData) => string | null | undefined;
    additionalStatParams?: Omit<FormatStatValueParams, 'value' | 'currencyCode' | 'convertPriceFromUSD'> &
      BaseParams<TRowData> &
      Pick<Column<TRowData, TRowKey, TSortKey, TFilters>, 'width'> & {
        insideParantheses?: boolean;
      };
  }): Column<TRowData, TRowKey, TSortKey, TFilters> {
  return {
    ...other,
    key,
    width,
    renderTitle: other.renderTitle
      ? other.renderTitle
      : (props) => (
          <DefaultTitle justify={justify} {...props}>
            {title}
          </DefaultTitle>
        ),
    renderData: (rowData) => {
      const value = getValue(rowData);
      const subText = getSubText?.(rowData);
      const changeValue = getChangeValue ? getChangeValue(rowData) : value;
      const isPositive = typeof changeValue === 'number' && changeValue > 0;
      const isNegative = typeof changeValue === 'number' && changeValue < 0;
      const icon = getIcon?.(rowData);
      const linkUrl = getLinkUrl?.(rowData);

      const additionalStatChangeValue = getChangeValue
        ? getChangeValue(rowData)
        : additionalStatParams?.getValue(rowData);
      const additionalStatIsPositive = typeof additionalStatChangeValue === 'number' && additionalStatChangeValue > 0;
      const additionalStatIsNegative = typeof additionalStatChangeValue === 'number' && additionalStatChangeValue < 0;

      const content = (
        <>
          <span
            className={clsx(classes.stat, {
              [classes.statWithSubText]: !!subText && bypassStatWithSubTextClass === false,
            })}
          >
            {icon && <FontAwesomeIcon className={classes.icon} icon={icon} />}
            {formatStatValue({
              type,
              value: getValue(rowData),
              defaultValue,
              signDisplay,
              formatOverrides,
            })}
            {additionalStatParams && (
              <span
                className={clsx(classes.additionalStatText, {
                  [classes.positive]: additionalStatIsPositive,
                  [classes.negative]: additionalStatParams.highlightChange && additionalStatIsNegative,
                  [classes.additionalStatNeutralText]:
                    additionalStatParams.highlightChange && !additionalStatIsPositive && !additionalStatIsNegative,
                })}
              >
                {`${additionalStatParams.insideParantheses ? '(' : ''}${additionalStatParams.formatStatValue({
                  type: additionalStatParams.type,
                  value: additionalStatParams.getValue(rowData),
                  defaultValue: additionalStatParams.defaultValue,
                  signDisplay: additionalStatParams.signDisplay,
                  formatOverrides: additionalStatParams.formatOverrides,
                })}${additionalStatParams.insideParantheses ? ')' : ''}`}
              </span>
            )}
          </span>
          {!!subText && <span className={classes.subText}>{subText}</span>}
        </>
      );

      return (
        <TextCell
          justify={justify}
          className={clsx({
            [classes.positive]: highlightChange && isPositive,
            [classes.negative]: highlightChange && isNegative,
            [classes.primary]: variant === 'primary',
            [classes.secondary]: variant === 'secondary',
          })}
        >
          {linkUrl && typeof linkUrl === 'string' && linkUrl.length > 0 ? (
            <a href={linkUrl} target="_blank" rel="noreferrer" className={classes.statLink}>
              {content}
            </a>
          ) : (
            <>{content}</>
          )}
        </TextCell>
      );
    },
    groupId,
  };
}
