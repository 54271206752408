import { mmApiClient, MmApiInput, MmApiOutput, MmApiQueryOptions, useAuthenticatedMMAPIQuery } from '@/services/mmApiX';

export type ListCollectionCategoriesInput = Exclude<MmApiInput['private']['collection']['categories']['list'], void>;
export type ListCollectionCategoriesOutput = Exclude<MmApiOutput['private']['collection']['categories']['list'], void>;

export const useListCollectionCategoriesKeyPrefix = 'private.collection.categories.list';
type QueryKey = [
  typeof useListCollectionCategoriesKeyPrefix,
  ListCollectionCategoriesInput['filters']['collectibleType'],
  ListCollectionCategoriesInput
];

export const getListCollectionCategoriesBaseQueryKey = ({
  collectibleType,
}: {
  collectibleType: ListCollectionCategoriesInput['filters']['collectibleType'];
}): [typeof useListCollectionCategoriesKeyPrefix, ListCollectionCategoriesInput['filters']['collectibleType']] => [
  useListCollectionCategoriesKeyPrefix,
  collectibleType,
];

export default function useCollectionCategories(
  params: ListCollectionCategoriesInput,
  options?: MmApiQueryOptions<ListCollectionCategoriesOutput, QueryKey>
) {
  const queryKey: QueryKey = [useListCollectionCategoriesKeyPrefix, params.filters.collectibleType, params];
  const result = useAuthenticatedMMAPIQuery(
    queryKey,
    () => mmApiClient.private.collection.categories.list.query(params),
    options
  );

  return result;
}
