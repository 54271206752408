import clsx from 'clsx';
import DefaultCellPadding, { DefaultCellPaddingProps } from './DefaultCellPadding';

import classes from './TextCell.module.scss';

export type Justify = 'start' | 'end' | 'center';

export default function TextCell({
  className,
  justify,
  ...other
}: DefaultCellPaddingProps & {
  justify?: Justify;
}) {
  return (
    <DefaultCellPadding
      {...other}
      className={clsx(
        classes.cell,
        {
          [classes.justifyStart]: justify === 'start',
          [classes.justifyEnd]: justify === 'end',
          [classes.justifyCenter]: justify === 'center',
        },
        className
      )}
    />
  );
}
