import { DetailedHTMLProps, HTMLAttributes } from 'react';
import clsx from 'clsx';

import classes from './SubSectionHeader.module.scss';

export type SubSectionHeaderProps = DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>;

export default function SubSectionHeader({ className, ...other }: SubSectionHeaderProps) {
  return <h3 {...other} className={clsx(classes.root, className)} />;
}
