import { useMemo } from 'react';
import { CollectionCategory } from '@sportscardinvestor/schemas';
import CategoryFilter, {
  CategoryFilterProps,
} from '../../sci-ui-components/collectibles/CategoryFilter/CategoryFilter';
import { CollectibleType } from '../../sci-ui-components/types/collectibleType';
import { collectibleNames } from '../../sci-ui-components/collectibles/constants';
import { NoCategory } from '../../services/sciApi/collections/types';
import useCollectionCategories from './useCollectionCategories';
import useCollectionCategoryMutations from './useCollectionCategoryMutations';
import { addOrEditCollectionCategory } from './AddOrEditCollectionCategoryDialog';
import { Option } from 'sci-ui-components/forms/SimpleSelect/SimpleSelect';

interface CategoryOption {
  id: number;
  name: string;
}

export default function CollectionCategoryFilter({
  onChange,
  collectibleType,
  noValueLabel = `All ${collectibleNames[collectibleType].shortPlural}`,
  selectedCategoryId,
  autoSelectAdded = false,
  withNone = false,
  disableEdit = false,
  disableAdd = false,
  ...other
}: Omit<
  CategoryFilterProps<CollectionCategory, number>,
  'getId' | 'getLabel' | 'categories' | 'onAddNew' | 'onDelete' | 'onEdit' | 'notEditable'
> & {
  autoSelectAdded?: boolean;
  collectibleType: CollectibleType;
  withNone?: boolean;
  disableEdit?: boolean;
  disableAdd?: boolean;
}) {
  const { data: categories } = useCollectionCategories({ filters: { collectibleType } });
  const { removeWithConfirmation } = useCollectionCategoryMutations();

  const categoryOptions = useMemo(
    () =>
      categories?.items?.reduce<CategoryOption[]>(
        (acc, item) => {
          acc.push({
            id: item.id,
            name: item.name,
          });
          return acc;
        },
        withNone
          ? [
              {
                id: NoCategory,
                name: 'No Category',
              },
            ]
          : []
      ) ?? [],
    [categories, withNone]
  );

  const filteredOptions = useMemo(
    () => (input: string, option: Option) => {
      return option.label?.toLowerCase().includes(input.toLowerCase());
    },
    []
  );

  return (
    <CategoryFilter
      {...other}
      selectedCategoryId={selectedCategoryId}
      noValueLabel={noValueLabel}
      getId={({ id }) => id}
      getLabel={({ name }) => name}
      categories={categoryOptions}
      onChange={onChange}
      onAddNew={
        !disableAdd
          ? async () => {
              const maybeNewCategory = await addOrEditCollectionCategory({ collectibleType });
              if (maybeNewCategory && autoSelectAdded) {
                onChange(maybeNewCategory.id);
              }
            }
          : undefined
      }
      onEdit={
        !disableEdit
          ? async (category) => {
              const maybeCategory = await addOrEditCollectionCategory({
                categoryId: category.id,
                name: category.name,
                collectibleType,
              });
              if (maybeCategory) {
                onChange(maybeCategory.id);
              }
            }
          : undefined
      }
      onDelete={
        !disableEdit
          ? async (category) => {
              const deleted = await removeWithConfirmation({
                id: category.id,
                collectibleType,
              });
              if (deleted) {
                onChange(null);
              }
            }
          : undefined
      }
      filterOption={(input, option) => filteredOptions(input, option)}
      notEditable={selectedCategoryId === NoCategory}
    />
  );
}
