import createDialogSingleton, { DialogProviderProps } from '../../../sci-ui-components/utils/createDialogSingleton';
import { AddOrEditCollectionCategoryDialogInfoProps, AddOrEditCollectionCategoryDialogResult } from './types';

const { invokeDialog: addOrEditCollectionCategory, registerDialog } = createDialogSingleton<
  AddOrEditCollectionCategoryDialogInfoProps,
  AddOrEditCollectionCategoryDialogResult
>();

export { addOrEditCollectionCategory, registerDialog };
export type AddOrEditCollectionCategoryDialogProviderProps = DialogProviderProps<
  AddOrEditCollectionCategoryDialogInfoProps,
  AddOrEditCollectionCategoryDialogResult
>;
