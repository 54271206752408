import { useMemo } from 'react';
import { mmApiClient, MmApiInput, MmApiOutput, MmApiQueryOptions, useAuthenticatedMMAPIQuery } from '@/services/mmApiX';
import useCurrencyFormatter from 'hooks/useCurrencyFormatter';

type ListPlayerMovementsInput = Exclude<MmApiInput['private']['movements']['listPlayers'], void>;
export type ListPlayerMovementFiltersExtended = Omit<
  ListPlayerMovementsInput['filters'],
  'minPeriodEndAvgPrice' | 'maxPeriodEndAvgPrice'
> & {
  minPeriodGroupAvgPriceInDisplayCurrency?: number;
  maxPeriodGroupAvgPriceInDisplayCurrency?: number;
};
export type ListCollectibleMovementsInputExtended = Omit<ListPlayerMovementsInput, 'filters'> & {
  filters: ListPlayerMovementFiltersExtended;
};
export type ListPlayerMovementsSortByValue = Exclude<ListPlayerMovementsInput['sort'], void>[number]['sortBy'];
export type ListPlayerMovementsOutput = Exclude<MmApiOutput['private']['movements']['listPlayers'], void>;
export type ListFavoritePlayerOutputItem = ListPlayerMovementsOutput['items'][number];

export const listPlayersMovementsQueryPrefix = 'private.movements.listPlayers' as const;

//prefix, isCollection, offset, params
type ListPlayerMovementsQueryKey = [typeof listPlayersMovementsQueryPrefix, boolean, number, ListPlayerMovementsInput];

export const getListPlayerMovementsQueryKeyForCollection = () => [listPlayersMovementsQueryPrefix, true];

const getListPlayerMovementsQueryKey = (params: ListPlayerMovementsInput): ListPlayerMovementsQueryKey => {
  return [listPlayersMovementsQueryPrefix, !!params.filters.collectionCategoryId, params.offset ?? 0, params];
};

const listPlayers = (input: ListPlayerMovementsInput) =>
  input.filters.collectionCategoryId
    ? mmApiClient.private.movements.collection.listPlayers.query({
        ...input,
        filters: {
          ...input.filters,
          collectionCategoryId: input.filters.collectionCategoryId!,
        },
      })
    : mmApiClient.private.movements.listPlayersv2.query(input);

export default function useListPlayerMovementsPaginated(
  {
    filters: { minPeriodGroupAvgPriceInDisplayCurrency, maxPeriodGroupAvgPriceInDisplayCurrency, ...baseFilters },
    ...baseParams
  }: ListCollectibleMovementsInputExtended,
  options?: MmApiQueryOptions<ListPlayerMovementsOutput, ListPlayerMovementsQueryKey>
) {
  const { convertPriceToUSD } = useCurrencyFormatter();

  const params = {
    ...baseParams,
    filters: {
      ...baseFilters,
      minPeriodGroupAvgPrice: convertPriceToUSD({
        value: minPeriodGroupAvgPriceInDisplayCurrency,
      }),
      maxPeriodGroupAvgPrice: convertPriceToUSD({
        value: maxPeriodGroupAvgPriceInDisplayCurrency,
      }),
    },
  };

  const { data: baseData, ...queryResult } = useAuthenticatedMMAPIQuery(
    getListPlayerMovementsQueryKey(params),
    () => listPlayers(params),
    options
  );

  const { data: countData } = useAuthenticatedMMAPIQuery(
    getListPlayerMovementsQueryKey({ ...params, offset: 0 }),
    () => listPlayers({ ...params, offset: 0 }),
    options
  );

  const data = useMemo(() => {
    return {
      items: baseData?.items as ListFavoritePlayerOutputItem[],
      count: countData?.count,
    };
  }, [baseData, countData]);

  return { ...queryResult, data };
}
