import { useMutation, useQueryClient } from 'react-query';
import { CollectibleType } from '@sportscardinvestor/schemas';
import { trackEvent } from '../analytics/trackEvent';
import { getListCollectionCategoriesBaseQueryKey } from './useCollectionCategories';
import { useListCollectionItemsKeyPrefix } from './useListCollectionItems';
import { useAuthenticatedMMApiMutation, MmApiInput, MmApiOutput, mmApiClient } from 'services/mmApiX/index';
import { waitForConfirmation } from 'sci-ui-components/ConfirmationDialog';
import { showError, showSuccess } from 'services/toaster';

export type CreateCollectionCategoriesInput = Exclude<
  MmApiInput['private']['collection']['categories']['createMultiple'],
  void
>;
export type CreateCollectionCategoriesOutput = MmApiOutput['private']['collection']['categories']['createMultiple'];
export type CreateCollectionCategoryInput = Exclude<MmApiInput['private']['collection']['categories']['create'], void>;
export type UpdateCollectionCategoryInput = Exclude<MmApiInput['private']['collection']['categories']['update'], void>;
export type DeleteCollectionCategoryInput = Exclude<MmApiInput['private']['collection']['categories']['delete'], void>;

export default function useCollectionCategoryMutations() {
  const queryClient = useQueryClient();
  const create = useMutation({
    mutationFn: (params: CreateCollectionCategoryInput) =>
      mmApiClient.private.collection.categories.create.mutate(params),
    mutationKey: ['add-collection-category'],
    onSuccess: (_, params) => {
      queryClient.invalidateQueries(
        getListCollectionCategoriesBaseQueryKey({ collectibleType: params.collectibleType })
      );
      showSuccess({
        description: `Successfully added "${params.name}" collection category`,
      });
    },
    onError: () => {
      showError({
        description: 'This category already exists.',
      });
    },
  });
  const createMultiple = useAuthenticatedMMApiMutation(
    (params: CreateCollectionCategoriesInput) =>
      mmApiClient.private.collection.categories.createMultiple.mutate(params),
    {
      mutationKey: ['add-multiple-collection-category'],
      onSuccess: (_, params) => {
        queryClient.invalidateQueries(
          getListCollectionCategoriesBaseQueryKey({ collectibleType: params.collectibleType })
        );
        showSuccess({
          description: `Successfully added "${params.names.join(', ')}" to collection categories`,
        });
        trackEvent({
          eventName: 'COLLECTION_CATEGORY_IMPORT_COMPLETED',
          collectibleType: params.collectibleType,
        });
      },
      onError: (_, { collectibleType }) => {
        showError({
          description: 'This category already exists.',
        });
        trackEvent({
          eventName: 'COLLECTION_CATEGORY_IMPORT_FAILED',
          collectibleType,
        });
      },
    }
  );
  const update = useMutation({
    mutationFn: (
      params: UpdateCollectionCategoryInput & {
        collectibleType: CollectibleType;
      }
    ) => mmApiClient.private.collection.categories.update.mutate(params),
    mutationKey: ['update-collection-category'],
    onSuccess: (_, params) => {
      queryClient.invalidateQueries(
        getListCollectionCategoriesBaseQueryKey({ collectibleType: params.collectibleType })
      );
      showSuccess({
        description: `Successfully renamed collection category to "${params.name}"`,
      });
    },
    onError: () => {
      showError({
        description: 'Failed to rename collection category. Please try again.',
      });
    },
  });
  const remove = useMutation({
    mutationFn: (
      params: DeleteCollectionCategoryInput & {
        collectibleType: CollectibleType;
      }
    ) => mmApiClient.private.collection.categories.delete.mutate(params),
    mutationKey: ['delete-collection-category'],
    onSuccess: (_, params) => {
      queryClient.invalidateQueries(
        getListCollectionCategoriesBaseQueryKey({ collectibleType: params.collectibleType })
      );
      queryClient.refetchQueries([useListCollectionItemsKeyPrefix]);
      showSuccess({
        description: 'Successfully deleted collection category',
      });
    },
    onError: () => {
      showError({
        description: 'Failed to delete collection category. Please try again.',
      });
    },
  });
  const removeWithConfirmation = async (
    params: DeleteCollectionCategoryInput & {
      collectibleType: CollectibleType;
    }
  ) => {
    trackEvent({
      eventName: 'COLLECTION_CATEGORY_DELETE_STARTED',
    });
    const confirmed = await waitForConfirmation({
      title: 'Delete collection category',
      description: 'Are you sure you want to delete this collection category?',
    });
    if (confirmed) {
      await remove.mutateAsync(params, {
        onSuccess: () => {
          trackEvent({
            eventName: 'COLLECTION_CATEGORY_DELETE_COMPLETED',
          });
        },
        onError: () => {
          trackEvent({
            eventName: 'COLLECTION_CATEGORY_DELETE_FAILED',
          });
        },
      });
    } else {
      trackEvent({
        eventName: 'COLLECTION_CATEGORY_DELETE_CANCELLED',
      });
    }
    return confirmed;
  };

  return {
    create,
    createMultiple,
    removeWithConfirmation,
    update,
  };
}
